import styled from 'styled-components';
import { Alert } from '@material-ui/lab';
import { colors } from '../css';
import { TOAST_TYPE } from './lib';

const getBorderColor = {
  [TOAST_TYPE.SUCCESS]: colors.colorGreenRgba(0.2),
  [TOAST_TYPE.ERROR]: colors.colorRedRgba(0.2),
  [TOAST_TYPE.WARNING]: colors.colorSand02Rgba(0.2),
  [TOAST_TYPE.INFO]: colors.colorBlueRgba(0.2)
};

export const StyledAlert = styled(Alert)`
  max-width: 700px;
  margin: auto;
  margin-bottom: 40px;
  border: 0.2px solid ${props => getBorderColor[props.severity]};
  div {
    align-items: center;
  }
`;
