export const TOAST_TYPE = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error'
};

export const toastHeader = {
  success: 'Success',
  info: 'Information',
  warning: 'Warning',
  error: 'Error'
};
