/**
 * The dependencies below are used across many pages.
 *
 * Importing them in "_app.js" moves them in to the common webpack
 * bundle rather than having duplicates in the bundles for every page.
 *
 * Use the script "npm run analyze" to see the results.
 */

import NextProgress from 'next-progress';
/**
 * When another language such as french needs to be added to the site the
 * following code should be added to this file:
 *
 * import { addLocaleData } from 'react-intl';
 * import fr from 'react-intl/locale-data/fr';
 * addLocaleData([...fr]);
 */

import get from 'lodash.get';

import App from 'next/app';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import Helmet from 'react-helmet';
import { IntlProvider } from 'react-intl';

import * as Sentry from '@sentry/react';
import { Toaster } from 'react-hot-toast';
import ErrorPage from '../components/ErrorPage';

import Favicon from '../components/Favicon';
import PageSEO from '../components/PageSEO';

import withApollo from '../hocs/withApollo';
import WithAdminEditToast from '../hocs/withAdminEditToast';
import { getAuthStore, getVersionInfoStore } from '../hocs/withAuth/service';
import initApollo from '../apollo';

import DefaultLayout from '../layouts/DefaultLayout';

import MaterialSSR from '../lib/material-ui/MaterialSSR';

import { getDefaultLocale, LocaleProvider, getActiveLocaleFromContext } from '../locales';

/**
 * Custom App component for the LodgeLink application.
 *
 * https://nextjs.org/docs/#custom-%3Capp%3E
 */
class LodgeLinkApp extends App {
  static async getInitialProps({ Component, ctx }) {
    // Localization
    const locale = getActiveLocaleFromContext(ctx);
    const localeMessages = LodgeLinkApp.getLocaleMessages(ctx);

    // Augment context with locale because it may be useful in getInitialProps on pages
    ctx = {
      locale,
      ...ctx
    };

    // Execute getInitialProps with our extended context
    let pageProps = {};
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    // Augment pageProps with locale because it may be useful to know on pages
    pageProps = {
      locale,
      ...pageProps
    };

    return {
      path: ctx.asPath,
      locale,
      localeMessages,
      pageProps
    };
  }

  /**
   * Get localized messages.
   *
   * Pulled from this example:
   * https://github.com/zeit/next.js/blob/canary/examples/with-react-intl/pages/_app.js
   *
   * @param {Object} ctx
   */
  static getLocaleMessages(ctx) {
    const { req } = ctx;

    // On initial page load express sends through all of the translated messages on the request.
    // Used for server side rendering
    if (req && req.localeMessages) {
      return req.localeMessages;
    }

    // After the initial page load messages are pulled from `__NEXT_DATA__`.
    // Used for client side rendering
    if (process.browser) {
      return get(window, '__NEXT_DATA__.props.localeMessages', {});
    }

    // Something went very wrong if you got here
    return {};
  }

  componentDidUpdate() {
    let authStore;
    let versionInfoStore;

    versionInfoStore = getVersionInfoStore();
    const cachedBuildId = versionInfoStore.get('buildId');
    const buildId = get(window, '__NEXT_DATA__.buildId', 'DEFAULT-BUILD-ID');

    if (!cachedBuildId) {
      // No cached Build ID; create one
      versionInfoStore.set('buildId', buildId);
    } else if (cachedBuildId !== buildId) {
      // Upon detecting a Build ID change, stash auth and clear storage
      // then recreate auth & version info stores and re-initialize Apollo client
      authStore = getAuthStore();
      const authStash = {
        token: authStore.get('token'),
        expiry: authStore.get('expiry'),
        authError: authStore.get('authError')
      };
      window.localStorage.clear();
      authStore = getAuthStore(true);
      authStore.set('token', authStash.token);
      authStore.set('expiry', authStash.expiry);
      authStore.set('authError', authStash.authError);
      versionInfoStore = getVersionInfoStore(true);
      versionInfoStore.set('buildId', buildId);
      initApollo({}, {}, true);
    }
  }

  render() {
    const { apolloClient, Component, locale, localeMessages, pageProps, path } = this.props;

    return (
      <>
        <NextProgress color="#00B361" options={{ showSpinner: false }} />
        <MaterialSSR>
          <Favicon />
          <LocaleProvider value={locale}>
            <IntlProvider
              defaultLocale={getDefaultLocale().locale}
              initialNow={Date.now()}
              locale={locale.locale}
              messages={localeMessages}
            >
              <ApolloProvider client={apolloClient}>
                <Helmet
                  htmlAttributes={{ lang: locale.locale.substring(0, 2) }}
                  meta={[
                    { charset: 'UTF-8' },
                    { 'http-equiv': 'Content-Language', content: locale.locale },
                    { name: 'viewport', content: 'width=device-width, initial-scale=1' }
                  ]}
                />
                <DefaultLayout>
                  <PageSEO />
                  {/*
                        Adding a key here forces "pages" to re mount whenever the url changes.

                        If you are on a page and re-route to the same page with altered query parameters the
                        page will re-mount where previously it would only have it's props updated.

                        This has been added late in the project and did not get a lot of testing to
                        see if it has an adverse side effects.
                      */}
                  <WithAdminEditToast>
                    <Sentry.ErrorBoundary fallback={<ErrorPage statusCode={null} />}>
                      <Component key={path} {...pageProps} />
                    </Sentry.ErrorBoundary>
                  </WithAdminEditToast>
                </DefaultLayout>
                <Toaster position="bottom-left" />
              </ApolloProvider>
            </IntlProvider>
          </LocaleProvider>
        </MaterialSSR>
      </>
    );
  }
}

export default compose(withApollo)(LodgeLinkApp);
