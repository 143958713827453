import React from 'react';
import { flowRight as compose } from 'lodash';
import { intlShape, injectIntl } from 'react-intl';
import withAuth from '../withAuth';
import messages from './messages';
import dispatchToast from '../../components/ToastComponent';
import { TOAST_TYPE } from '../../components/ToastComponent/lib';

/**
 * This HOC is simply meant to wrap around the entire customer portal in _app.js
 * with the purpose of dispatching a toast if an admin is coming in from the admin
 * portal to make an edit on behalf of someone else in an organization
 */
class WithAdminEditToast extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired
  };

  componentDidMount() {
    const { auth } = this.props;
    // on mount, check if user is coming in as an admin from admin portal
    if (auth.me) {
      const { adminToken } = auth.me;
      if (adminToken !== null) {
        this.displayAdminEditToast(adminToken);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { auth } = this.props;
    if (prevProps.auth.me !== auth.me && auth.me) {
      const { adminToken } = auth.me;
      if (adminToken !== null) {
        this.displayAdminEditToast(adminToken);
      }
    }
  }

  /**
   * will display a toast to notify an admin coming from the admin portal of the identity they are impersonating
   */
  displayAdminEditToast(adminToken) {
    const { intl } = this.props;
    const { organization, identityOnBehalf } = adminToken;
    const { name } = organization;
    const { emailAddress } = identityOnBehalf;
    this.dispatchAdminEditToast(intl.formatMessage(messages.adminEditMessage, { emailAddress, name }));
  }

  dispatchAdminEditToast(inputMessage) {
    const message = inputMessage;
    dispatchToast(TOAST_TYPE.INFO, message, null, { duration: Infinity });
  }

  render() {
    const { children } = this.props;
    return children;
  }
}
export default compose(
  injectIntl,
  withAuth()
)(WithAdminEditToast);
