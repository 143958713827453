import React from "react";
import { StyledAlert } from "./styles";
import { TOAST_TYPE, toastHeader } from "./lib";
import Text from "../BuildingBlocks/Text";
import toast from "react-hot-toast";
import RichText from "../RichText/RichText";

/**
 * ToastComponent displays a customizable toast notification with a header, body, and helper content.
 * 
 * @param {Toast} toastData - Data from given toast to perform operations on a given toast
 * @param {TOAST_TYPE} [toastType=TOAST_TYPE.INFO] - The type of the toast (e.g., INFO, SUCCESS, ERROR).
 * @param {string} toastBody - The body text of the toast.
 * @param {*} toastHelper - Additional helper content to be displayed in the toast (e.g., icons, buttons). JSX Element.
 * @returns {JSX.Element} A styled alert containing the toast content.
 */
export function ToastComponent({
  toastData,
  toastType = TOAST_TYPE.INFO,
  toastBody,
  toastHelper
}) {

  return (
    <div>
      <StyledAlert
        severity={toastType}
        onClose={() => toast.dismiss(toastData.id)}
      >
        <Text.HeadingFourth noMargins >
          {toastHeader[toastType]}
        </Text.HeadingFourth>
        <Text.CopyParagraph noMargins>
          <RichText content={toastBody} />
        </Text.CopyParagraph>
        {toastHelper}
      </StyledAlert>
    </div>
  );
}

export default function dispatchToast(
  toastType = TOAST_TYPE.INFO,
  toastBody,
  toastHelper = null,
  modifyToast = {}
) {
  toast.custom((t) => (
    <ToastComponent
      toastData={t}
      toastType={toastType}
      toastBody={toastBody}
      toastHelper={toastHelper}
    />
  ), modifyToast)
};